import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import { Configurator, LandingPage } from '../pages';

import { I18nPropvider, LOCALES } from '../i18nProvider';
import translate from '../i18nProvider/translate';



export function App(props) {

    const [locale, setLocale] = useState(LOCALES.ENGLISH);

    const toggelLanguage = () => {
        
        locale == "en-us" ? setLocale(LOCALES.GERMAN) : setLocale(LOCALES.ENGLISH)
    }

    return (
        <I18nPropvider locale={locale}>
            <Routes>
                <Route path="/configurator" element={<Configurator client={props} />} />
                <Route path="/" element={<LandingPage client={props} />} />
            </Routes>
            {/* <button onClick={() => setLocale(LOCALES.ENGLISH)}>English</button>
            <button onClick={() => setLocale(LOCALES.GERMAN)}>German</button> */}
            <a href="#" className="float">
                <span className='my-float' onClick={toggelLanguage}>{translate('nav-lang')}</span>
            </a>
        </I18nPropvider>
    )
}



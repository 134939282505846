import { LOCALES } from '../constants';

export default {
  [LOCALES.GERMAN]: {
    'nav-lang': 'DE',
    'main-slider': 'Design dein Armband',
    'slider-1': 'Design dein Armband',
    'slider-2': 'Design dein Armband',
    'slider-3': 'Design dein Armband',
    'slider-4': 'Design dein Armband',
    'guide-text': 'DESIGN DIR DEIN ARMBAND',
    'nav-sets': 'Sets',
    'nav-leaves': 'Leaves',
    'nav-about': 'Über Uns',
    'nav-size-help': 'Größenhilfe',
    'cat-alphabets': 'Buchstaben',
    'cat-symbols': 'Symbole',
    'cat-stars': 'Sternzeichen',
    'cat-stones': 'Steine',
    'text-cart': 'Warenkorb',
    'text-bracelet-length': 'Armbandlänge',
    'text-your-selection': 'DEINE AUSWAHL',
    'text-your-starter-bracelet': 'Dein Starter Armband enthält aktuell',
    'text-every-bracelet': 'Jedes Armband startet mit unseren BLANCO LEAVES, die du ganz einfach von unten nach oben ziehen und tauschen kannst. Dabei werden die Preise automatisch angepasst.',
    'text-shoten-or': 'Kürze oder verlängere dein Armband durch klicken auf das Minus und Plus Symbol.',
    'text-your-selection-will': 'Deine Auswahl wird in diesem Fenster angezeigt sobald du ein neues LEAVE in dein Armband ziehst.',
    'text-you-need-help': 'Du benötigst Hilfe bei der Längenwahl? Klicke oben einfach auf „Größenhilfe“. .',
  },
};
import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH]: {
    'nav-lang': 'EN',
    'main-slider': 'Design your bracelet',
    'slider-1': 'Design your bracelet',
    'slider-2': 'Design your bracelet',
    'slider-3': 'Design your bracelet',
    'slider-4': 'Design your bracelet',
    'guide-text': 'DESIGN YOUR BRACELET',
    'nav-sets': 'Sets',
    'nav-leaves': 'Leaves',
    'nav-about': 'About Us',
    'nav-size-help': 'Size Help',
    'cat-alphabets': 'Alphabets',
    'cat-symbols': 'Symbols',
    'cat-stars': 'Star Signs',
    'cat-stones': 'Stones',
    'text-cart': 'Cart',
    'text-bracelet-length': 'Bracelet Length',
    'text-your-selection': 'Your Selection',
    'text-your-starter-bracelet': 'Your starter bracelet currently contains',
    'text-every-bracelet': 'Every bracelet starts with our BLANCO LEAVES, which you can easily pull up and swap out. The prices are automatically adjusted.',
    'text-shoten-or': 'Shorten or lengthen your bracelet by clicking on the minus and plus symbols.',
    'text-your-selection-will': 'Your selection will appear in this window as soon as you drag a new LEAVE into your bracelet.',
    'text-you-need-help': 'Need help choosing a length? Just click on "Size Guide" above. .',
  },
};
import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Row, Col } from "antd";
import {
  CloseOutlined,
  DownOutlined,
  UpOutlined,
  ZoomInOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Links } from "../../assets";
import {
  BraceletModal,
  DroppableComp,
  DraggableComp,
  LoaderComp,
  Nav,
  Overlay,
  Guide,
} from "../../components";
import { categories } from "../../config";
import Client from "shopify-buy";
import "./configurator.css";
import translate from "../../i18nProvider/translate";

const client = Client.buildClient({
  storefrontAccessToken: "b115721510b772788c02a084c1ecf5ea",
  domain: "tyche-jewelry-official.myshopify.com",
});

export default function Configurator() {
  const [selections, setSelections] = useState([]);

  const [leaves, setLeaves] = useState([]);

  const [item, setItem] = useState(null);

  const [categoryIndex, setCategoryIndex] = useState(0);

  const [size, setSize] = useState(16);

  const [isAddToCart, setIsAddToCart] = useState(false);

  const [checkoutId, setCheckoutId] = useState(null);

  const [checkout, setCheckout] = useState(null);

  const [webURL, setWebURL] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [bracelet, setBracelet] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  const [braceletPrice, setBraceletPrice] = useState(bracelet?.length * 10);

  const [visible, setVisible] = useState(false);

  const [overlay, setOverlay] = useState(false);

  const [single, setSingle] = useState(null);

  const [guide, setGuide] = useState(false);

  const addToCartMargin =
    selections.length === 23
      ? "340px"
      : selections.length === 22
      ? "320px"
      : selections.length === 21
      ? "305px"
      : selections.length === 20
      ? "295px"
      : selections.length === 19
      ? "270px"
      : selections.length === 18
      ? "255px"
      : selections.length === 17
      ? "240px"
      : selections.length === 16
      ? "225px"
      : selections.length === 15
      ? "210px"
      : selections.length === 14
      ? "200px"
      : selections.length === 13
      ? "190px"
      : selections.length === 12
      ? "180px"
      : selections.length === 11
      ? "170px"
      : selections.length === 10
      ? "160px"
      : selections.length === 9
      ? "125px"
      : selections.length === 8
      ? "100px"
      : selections.length === 7 && "75px";
  useEffect(() => {
    const tempGuide = localStorage.getItem("guide");
    tempGuide ? setGuide(tempGuide) : setGuide(false);
  }, []);

  useEffect(() => {
    selectionAndPrize();
    createCheckOut();
  }, [client]);

  useEffect(() => {
    getProducts();
  }, [categoryIndex, client]);

  useEffect(() => {
    selectionAndPrize();
  }, [bracelet, client, checkout]);

  useEffect(() => {
    getSingleProduct();
  }, [checkoutId, client]);

  function dragEnd(res) {
    setIsLoading(true);
    setItem(null);
    const { source, destination } = res;

    if (destination && source.droppableId === "beats") {
      if (destination.droppableId === "beats") return;

      let tempArray = bracelet;

      if (tempArray[destination.index]) {
        removeLineItemInCart(tempArray[destination.index]).then(async () => {
          tempArray[destination.index] = leaves[source.index];

          await addVariantToCart(leaves[source.index].variantId, 1);

          setBracelet(null);

          setBracelet(tempArray);
        });
      } else {
        tempArray[destination.index] = leaves[source.index];

        addVariantToCart(leaves[source.index].variantId, 1).then(() => {
          setBracelet(null);
          setBracelet(tempArray);
          // selectionAndPrize();
        });
      }
    } else if (
      Number(source.droppableId) >= 0 &&
      Number(source.droppableId) < 23
    ) {
      if (!destination) return;

      if (
        Number(destination.droppableId) >= 0 &&
        Number(destination.droppableId) < 23
      ) {
        let tempArray = bracelet;

        if (tempArray[destination.index]) {
          removeLineItemInCart(tempArray[destination.index]).then(() => {
            tempArray[destination.index] = tempArray[source.index];

            tempArray[source.index] = null;

            setBracelet(null);

            setBracelet(tempArray);
          });
        } else {
          tempArray[destination.index] = tempArray[source.index];

          tempArray[source.index] = null;

          console.log(tempArray);

          setBracelet(null);

          setTimeout(() => setBracelet(tempArray), 50);
        }
      } else {
        let tempArray = bracelet;

        removeLineItemInCart(tempArray[source.index]).then(() => {
          // tempArray[destination.index] = tempArray[source.index];

          tempArray[source.index] = null;

          setBracelet(null);

          setBracelet(tempArray);
        });
      }
    }
    setIsLoading(false);
  }

  function selectionAndPrize() {
    let cartArray = [];
    let emptyArray = [];
    let arrayForPrizing = [];

    let total = 0;

    bracelet?.forEach((item) => {
      if (item?.price) {
        arrayForPrizing.push(item);
      } else {
        emptyArray.push(null);
      }
    });

    checkout?.lineItems?.forEach((item, index) => {
      const obj = {
        id: item.id,
        title: item.title,
        img: item.variant.image.src,
        variantId: item.variant.id,
        price: item.variant.price,
        quantity: item.quantity,
      };
      cartArray.push(obj);
    });

    setSelections([]);

    setTimeout(() => {
      setSelections(cartArray);
    }, 50);

    cartArray.map((item, index) => {
      total += Number(item.price.amount) * item.quantity;
      console.log(item.price.amount);
    });

    emptyArray.map((arr, ind) => {
      total += 40;
    });

    setBraceletPrice(total);

    setSelections([]);

    setSelections(cartArray);
    setIsLoading(false);
  }

  function onSizeChange(op) {
    let tempArray = bracelet;

    if (op === "add" && tempArray) {
      tempArray.length = size + 1;
      tempArray[tempArray?.length - 1] = null;
      setBraceletPrice((prev) => prev + 40);
      setBracelet(null);
      setTimeout(() => {
        setBracelet(tempArray);
      }, 0.5);
    } else if (op === "sub" && tempArray) {
      if (tempArray[tempArray.length - 1]) {
        console.log("malik kuch to hai");
        removeLineItemInCart(tempArray[tempArray.length - 1]).then(() => {
          tempArray.length = size - 1;
          setBraceletPrice((prev) => prev - 40);
          setBracelet(null);
          setTimeout(() => {
            setBracelet(tempArray);
          }, 0.5);
        });
      } else {
        console.log("malik kuch bhi nhi hai");
        tempArray.length = size - 1;
        setBraceletPrice((prev) => prev - 40);
        setBracelet(null);
        setTimeout(() => {
          setBracelet(tempArray);
        }, 0.5);
      }
    }
  }

  function addSub(op) {
    if (op === "add") {
      setSize((prev) => prev + 1);
    }
    if (op === "sub") {
      setSize((prev) => prev - 1);
    }

    onSizeChange(op);
  }

  function onCategorieChange(item, index) {
    setCategoryIndex(index);
  }

  function clearSelection() {
    let tempArray = [];

    createCheckOut();

    setBraceletPrice(bracelet.length * 40);

    bracelet.forEach((item) => {
      tempArray.push(null);
    });

    setBracelet(tempArray);

    setTimeout(() => setSelections([]), 1000);
  }

  async function addVariantToCart(variantId, quantity) {
    if (variantId) {
      const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];

      const res = await client.checkout.addLineItems(
        checkoutId,
        lineItemsToAdd
      );

      setCheckout(res);

      return res;
    }
  }

  async function proceedToCheckout() {
    let x = [];
    bracelet.forEach((item, index) => {
      if (!item) {
        x.push(null);
      }
    });

    addVariantToCart(single.variants[0].id, x.length).then(() => {
      client.checkout.fetch(checkoutId).then((checkout) => {
        // checkout.lineItems && window.open(webURL)
        if (checkout.lineItems) window.location.href = webURL;
      });
    });
  }

  async function removeLineItemInCart(lineItem) {
    let removingLeave;

    selections.forEach((selection) => {
      if (!selection) return;
      if (selection?.variantId === lineItem?.variantId)
        removingLeave = selection;
    });

    if (removingLeave.quantity > 1) {
      const lineItemsToUpdate = [
        {
          id: removingLeave.id,
          quantity: parseInt(removingLeave.quantity - 1, 10),
        },
      ];

      const res = await client.checkout.updateLineItems(
        checkoutId,
        lineItemsToUpdate
      );

      setCheckout(res);
    } else {
      const res = await client.checkout.removeLineItems(checkoutId, [
        removingLeave.id,
      ]);
      setCheckout(res);
    }
  }

  function createCheckOut() {
    client.checkout.create().then((checkout) => {
      setCheckout(checkout);

      setCheckoutId(checkout.id);

      setWebURL(checkout.webUrl);
    });
  }

  function getSingleProduct() {
    const handle = "einzelnes-leaf";

    client.product.fetchByHandle(handle).then((product) => {
      setSingle(product);
      console.log(product);
    });
  }

  function getProducts() {
    client.collection.fetchAllWithProducts().then((collections) => {
      client.collection
        .fetchWithProducts(collections[categoryIndex].id, { productsFirst: 50 })
        .then((collection) => {
          const tempArray = [];

          collection.products.forEach((item, index) => {
            const obj = {
              id: item.id,
              title: item.title,
              img: item.images[2].src,
              variantId: item.variants[0].id,
              price: item.variants[0].price,
              category: collections[categoryIndex].title,
              url: item.onlineStoreUrl,
            };
            tempArray.push(obj);
          });
          setTimeout(() => setLeaves(tempArray), 50);
          setLeaves(tempArray);
        });
    });
  }

  if (overlay) {
    return <Overlay visible={overlay} setVisible={setOverlay} />;
  } else {
    return (
      <DragDropContext onDragEnd={dragEnd}>
        {!guide && <Guide setGuide={setGuide} />}
        <Nav setOverlay={setOverlay} braceletPrice={braceletPrice} />
        <Row className="conf-row">
          <Col lg={17} md={16} sm={24} xs={24}>
            <div className="left-container">
              <Row>
                <Col lg={1} xs={2}>
                  <div className="left-icon-container h-100 ps-2 py-3">
                    <ZoomInOutlined
                      style={{ fontSize: "30px", color: "#949494" }}
                      onClick={() => setVisible(true)}
                    />
                    <DeleteOutlined
                      style={{ fontSize: "30px", color: "#949494" }}
                      onClick={clearSelection}
                    />
                  </div>
                </Col>
                <Col lg={{ offset: 1, span: 22 }} xs={{ offset: 1, span: 21 }}>
                  <div className="bracelet-container">
                    <div className="bracelet">
                      {bracelet && (
                        <>
                          {bracelet?.map((item, index) => (
                            <div className="bracelet-leaf-body">
                              <DroppableComp droppableId={`${index}`}>
                                <DraggableComp
                                  index={index}
                                  draggableId={`bracelet-draggable-${index}`}
                                >
                                  {item ? (
                                    <img
                                      className="leaf"
                                      src={item?.img}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="leaf"
                                      src="https://cdn.shopify.com/s/files/1/0620/5358/1019/products/DSC_5293-1_9a0bc38c-836c-48f9-a6d9-c00a2211b0d9.png?v=1640993777"
                                      alt=""
                                    />
                                  )}
                                </DraggableComp>
                              </DroppableComp>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col className="left-top" span={24}>
                  <div className="categories-container">
                    <Row
                      gutter={{ xs: 4, sm: 8, md: 12, lg: 16 }}
                      className="categories-row"
                    >
                      {categories.map((item, index) => (
                        <Col key={item.title} span={6}>
                          <div
                            onClick={() => onCategorieChange(item, index)}
                            style={{
                              border:
                                categoryIndex === index && "2px solid #dddddd",
                            }}
                            className="categories-card"
                          >
                            <img alt="" src={item.img} />
                            {item.name}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>
              <DroppableComp droppableId="beats">
                <Row gutter={[16, 16]} className="beats-container">
                  {leaves ? (
                    <>
                      {leaves?.map((item, index) => {
                        return (
                          <Col key={item.title} lg={3} md={3} sm={4} xs={6}>
                            <DraggableComp
                              draggableId={item.title}
                              index={index}
                            >
                              <div
                                className="beats-cards"
                                onClick={() => {
                                  setItem(item);
                                  setIsAddToCart(false);
                                }}
                              >
                                <img src={item.img} alt="" />
                              </div>
                            </DraggableComp>
                          </Col>
                        );
                      })}
                    </>
                  ) : (
                    <LoaderComp />
                  )}
                </Row>
              </DroppableComp>
            </div>
          </Col>
          <Col
            lg={{ offset: 0, span: 7 }}
            md={{ offset: 0, span: 8 }}
            sm={{ offset: 5, span: 14 }}
            xs={{ offset: 0, span: 24 }}
          >
            <div className="right-container">
              <div className="right-card">
                <div
                  onClick={() => setIsAddToCart(!isAddToCart)}
                  className="top-button-box"
                >
                  <Row>
                    {isAddToCart ? (
                      <Col span={24}>
                        <div
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            paddingTop: "7.5px",
                            borderTopLeftRadius: "75px",
                          }}
                          className="top-button-right"
                        >
                          <p> {translate("text-your-selection")} </p>
                        </div>
                      </Col>
                    ) : (
                      <>
                        <Col span={12}>
                          <div
                            style={{ backgroundColor: "#000", color: "#fff" }}
                            className="top-button-left"
                          >
                            €{braceletPrice}
                          </div>
                        </Col>
                        <Col
                          onClick={() => {
                            setIsAddToCart(true);
                            setItem(null);
                          }}
                          span={12}
                        >
                          <div className="top-button-right top-button-right-small">
                            {translate("text-cart")}
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
                <div className="item-top">
                  <div>
                    {!item && isAddToCart ? (
                      <>
                        <img src={Links} alt="" />
                        <UpOutlined
                          onClick={() => setIsAddToCart(false)}
                          style={{
                            fontSize: "25px",
                            color: "#b5b3b3",
                            margin: "1.5vh 1.5vw",
                          }}
                        />
                      </>
                    ) : !item && !isAddToCart ? (
                      <DownOutlined
                        onClick={() => {
                          setIsAddToCart(true);
                          setItem(null);
                        }}
                        style={{
                          fontSize: "25px",
                          color: "#b5b3b3",
                          margin: "1.5vh 1.5vw",
                          marginLeft: "90%",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {isLoading && !selections?.length ? (
                  <LoaderComp />
                ) : (
                  <>
                    {isAddToCart ? (
                      <>
                        <div className="add-to-cart-container">
                          <div className="add-to-cart-text-box">
                            <div className="add-to-cart-text-row">
                              <div className="size">{size} Leaves</div>
                              <button
                                disabled={size > 14 ? false : true}
                                style={{ opacity: size > 14 ? "1" : "0.2" }}
                                className="btns"
                                onClick={() => addSub("sub")}
                              >
                                -
                              </button>
                              <button
                                disabled={size < 19 ? false : true}
                                style={{ opacity: size < 19 ? "1" : "0.2" }}
                                className="btns"
                                onClick={() => addSub("add")}
                              >
                                +
                              </button>
                            </div>
                            <p className="bracelet-length">
                              {" "}
                              {translate("text-bracelet-length")}: ca.{" "}
                              {`${size + 2} cm`}
                            </p>
                          </div>
                          <hr />
                          <div
                            className="add-to-cart-scroll-area"
                            style={{ paddingTop: addToCartMargin }}
                          >
                            {selections.map((item) => (
                              <div key={item.title} className="image-text-row">
                                <div>
                                  <div className="img">
                                    <img src={item.img} alt="" />
                                  </div>
                                  <p>{item.title}</p>
                                </div>
                                <p>
                                  € {item.price.amount} x{" "}
                                  <span className="text-dark fw-bold">
                                    {item.quantity}
                                  </span>{" "}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          className="bottom-buttons"
                          onClick={proceedToCheckout}
                        >
                          <p>€{braceletPrice}</p>
                          <p className="fw-bold">{translate("text-cart")}</p>
                        </div>
                      </>
                    ) : (
                      <div className="right-text-box">
                        {item ? (
                          <div className="item-details">
                            <div className="item-top">
                              <CloseOutlined
                                style={{ fontSize: "25px", color: "#b5b3b3" }}
                                onClick={() => setItem(null)}
                              />
                            </div>
                            <h3>{item.title}</h3>
                            <img src={item.img} alt="" />
                            <h4>Preis: € {item.price.amount}</h4>
                            <a
                              className="bg-dark text-white py-1 px-2 rounded mt-3"
                              href={item.url}
                              target="_blank"
                            >
                              Produkt Details
                            </a>
                          </div>
                        ) : (
                          <>
                            {!selections?.length ? (
                              <h4 className="mb-2 fw-normal text-secondary">
                                {translate("text-your-starter-bracelet")}{" "}
                                <b className="text-dark">{size}</b> LEAVES
                              </h4>
                            ) : (
                              <></>
                            )}
                            <h2>{translate("text-your-selection")} </h2>
                            {selections.length ? (
                              <div className="selection-flow">
                                <Row gutter={[16, 16]}>
                                  {selections.map((item) => (
                                    <Col span={12}>
                                      <div className="selection-beats position-relative">
                                        <div className="rounded-circle bg-secondary text-white px-2 position-absolute top-0 end-0">
                                          {item.quantity}
                                        </div>
                                        <img src={item.img} alt="" />
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            ) : (
                              <>
                                <p>{translate("text-every-bracelet")}</p>
                                <p>{translate("text-shoten-or")}</p>
                                <span>
                                  {translate("text-your-selection-will")}
                                </span>
                                <span>{translate("text-you-need-help")}</span>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <BraceletModal
          visible={visible}
          setVisible={setVisible}
          bracelet={bracelet}
        />
      </DragDropContext>
    );
  }
}
